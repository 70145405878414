import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({ providedIn: 'root' })
export class ToastService {

  constructor(private _snackBar: MatSnackBar, private _zone: NgZone) {}

  success(message: string) {
    this._zone.run(() => {
      this._snackBar.open(message, undefined,{
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['success-snackbar']
      });
    });
  }

  error(message: string) {
    this._zone.run(() => {
      this._snackBar.open(message, undefined,{
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['error-snackbar']
      });
    });
  }

  warning(message: string) {
    this._zone.run(() => {
      this._snackBar.open(message, undefined,{
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['warning-snackbar']
      });
    });
  }
}
